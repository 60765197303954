<template>
    <el-container>
        <!-- 头部 -->
        <!-- <el-header height="auto" class="header"><member-header /></el-header> -->
		
		
		<!-- <div class="banner" v-loading="loadingAd" v-if="is_show">
		    <el-carousel height="70px" arrow="hover" direction="vertical" indicator-position="none">
		        <el-carousel-item v-for="item in adList" :key="item.adv_id" :style="{ backgroundColor: item.background }">
		            <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
		        </el-carousel-item>
		    </el-carousel>
		    <i class="el-icon-circle-close" @click="closeAd"></i>
		</div> -->
		<el-header height="auto" class="header"><ns-header /></el-header>
		
		
        <el-main class="content">
            <transition name="slide"><router-view /></transition>

            <!-- 右侧栏 -->
            <ns-aside />
        </el-main>
        <!-- 底部 -->
        <el-footer><ns-footer /></el-footer>
    </el-container>
</template>
<script>
    import MemberHeader from "./components/MemberHeader"
    import NsHeader from "./components/NsHeader"
    import NsAside from "./components/NsAside"
    import NsFooter from "./components/NsFooter"

    export default {
        created() {},
        data: () => {
            return {}
        },
        mounted() {},
        computed: {},
        watch: {},
        components: {
            MemberHeader,
			NsHeader,
            NsAside,
            NsFooter
        }
    }
</script>
<style lang="scss" scoped>
    .header {
        padding: 0;
    }
    .content {
        max-width: $width;
        margin: 0 auto;
        padding: 0;
    }
    .el-footer {
        padding: 0;
        height: auto !important;
        background-color: #fff;
        padding-top: 45px;
    }

    .el-main {
        border-top: none;
    }
</style>
